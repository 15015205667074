<template>
  <div class="main-order">
    <div class="panel1">
      <BriefcaseHorizontal />
    </div>
    <div class="card indent panel2">
      <Referral />
    </div>
    <div class="card indent panel3">
      <TransactionsHistory />
    </div>
    <div class="card indent panel4">
      <Orders />
    </div>
    <div class="card indent panel5">
      <Voting />
    </div>
    <div class="card indent panel6">
      <IncomeChart />
    </div>
  </div>
</template>

<script>
import BriefcaseHorizontal from "@/panels/components/BriefcaseHorizontal";
import IncomeChart from "@/components/dashboard/IncomeChart";
import Orders from "@/panels/components/Orders";
import Referral from "@/components/dashboard/Referral";
import TransactionsHistory from "@/components/dashboard/TransactionsHistory";
import Voting from "@/components/dashboard/Voting";
export default {
  name: "Dashboard2",
  components: { TransactionsHistory, IncomeChart, BriefcaseHorizontal, Orders, Voting, Referral }
};
</script>

<style scoped lang="scss">
.main-order {
  display: grid;
  gap: 20px;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:  "p1 p2 p3"
                        "p4 p4 p5"
                        "p4 p4 p6";
  @media screen and (max-width: 1500px){
    grid-template-rows: repeat(5, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:  "p1 p2"
                          "p3 p3"
                          "p5 p5"
                          "p4 p4"
                          "p6 p6"
  }
  @media screen  and (max-width: 900px){
    grid-template-rows: repeat(6, auto);
    grid-template-columns: 1fr;
    grid-template-areas:  "p1"
                          "p2"
                          "p3"
                          "p5"
                          "p4"
                          "p6"
  }
}
.panel1 {
  grid-area: p1;
}
.panel2 {
  grid-area: p2;
}
.panel3 {
  grid-area: p3;
}
.panel4 {
  grid-area: p4;
}
.panel5 {
  grid-area: p5;
}
.panel6 {
  grid-area: p6;
}
.indent {
  padding: 15px;
}
</style>