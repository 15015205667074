import App from "./pages/App.vue";
import auth from "./auth";
import axios from "axios";
import Components from "./components/extended/components";
import FloatingVue from "floating-vue";
import router from "./router";
import socket from "./socket";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
import VueYandexMetrika from "vue3-yandex-metrika";
import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { TonConnectUIPlugin } from "@townsquarelabs/ui-vue";

import "../public/scss/main.scss";
import "bootstrap/dist/css/bootstrap.css";

import i18n from "./localization/localization";

const options = {
  // manifestUrl: "http://localhost:8080/ton-connect/tonconnect-manifest_local.json" // for local testing
  manifestUrl: process.env.VUE_APP_HOST + process.env.VUE_APP_MANIFEST_URL || "https://cabinet.stg.autentic.capital/ton-connect/tonconnect-manifest.json"
};

const app = createApp(App)
  .use(store)
  .use(TonConnectUIPlugin, options)
  .use(router)
  .use(Components)
  .use(Toaster, {
    position: "top-right",
    duration: 4000,
    dismissible: true
  })
  .use(i18n)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(VueAxios, axios)
  .use(FloatingVue)
  .use(auth)
  .use(socket)
  .use(VueCookies, { expires: "14d" })
  .component("Apexchart", VueApexCharts);
app.axios.defaults.baseURL = process.env.VUE_APP_HOST_API;

app.config.globalProperties.$filters = {
  correctETH(eth) {
    return /^0x[a-fA-F0-9]{40}$/.test(eth);
  },
  shortAddress: (address) => {
    return address ? address.substr(0, 5) + "..." + address.substr(-5) : "";
  }
};

if (process.env.NODE_ENV === "production") {
  app.use(VueYandexMetrika, {
    id: process.env.VUE_APP_ANALYTICS_YANDEX_KEY,
    router: router,
    env: process.env.NODE_ENV,
    options: {
      clickmap: false
    }
  });
  app.use(createGtm({
    id: process.env.VUE_APP_ANALYTICS_GOOGLE_KEY
  }));
}
app.mount("#app");

import "bootstrap/dist/js/bootstrap.js";
import "floating-vue/dist/style.css";