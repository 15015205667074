<template>
  <div
    ref="dropdownRef"
    class="dropdown-container"
    :style="{ width: width }"
  >
    <div
      class="dropdown-selected"
      @click="toggleDropdown"
    >
      {{ selectedOption ? selectedOption.display : placeholder }}
      <AuIcon
        icon="chevron_down"
        class="select__chevron-icon"
        @click="toggleDropdown"
      />
    </div>
    <ul
      v-if="isOpen"
      class="dropdown-list"
    >
      <li
        v-for="option in options"
        :key="option.value"
        class="dropdown-item"
        @click="selectOption(option)"
      >
        {{ option.display }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Object,
      default: null
    },
    width: {
      type: String,
      default: "125px"
    }
  },
  emits: ["update:modelValue", "select"],
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    selectedOption() {
      return this.modelValue;
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.$emit("update:modelValue", option);
      this.$emit("select", option);
      this.isOpen = false;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    handleClickOutside(event) {
      const dropdown = this.$refs.dropdownRef;
      if (dropdown && !dropdown.contains(event.target)) {
        this.closeDropdown();
      }
    }
  }
};
</script>

<style scoped>
.dropdown-container {
  position: relative;
}

.dropdown-selected {
  height: 44px;
  border: 1px solid var(--color-70);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--menu-color);
  font-size: var(--font-size);
  padding-left: 14px;
}

.select__chevron-icon {
  position: absolute;
  width: 26px;
  height: 26px;
  right: 10px;
  top: 2px;
  text-align: center;
  padding-top: 14px;
  color: #505f79;
  font-size: var(--font-size);
}

.dropdown-list {
  position: absolute;
  width: 100%;
  margin-top: 5px;
  background: var(--menu-color);
  border: 1px solid var(--color-70);
  color: var(--text-color);
  list-style: none;
  padding: 0;
  border-radius: 8px;
  z-index: 100;
}

.dropdown-item {
  margin: 6px 0px;
  padding: 6px 0px 6px 14px;
  cursor: pointer;
  color: var(--text-color);
}

.dropdown-item:hover {
  background-color: var(--menu-color-select);
}
</style>
