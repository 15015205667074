// import TonConnect from "@tonconnect/sdk";
// import { CHAIN } from "@tonconnect/sdk";
import { useTonConnectUI, useTonWallet } from "@townsquarelabs/ui-vue";

export default {
  data() {
    return {
      providerTon: null,
      installTon: false,
      tonConnectUI: null,
      balanceWalletTon: null,
      connectedWalletTon: null
    };
  },
  mounted() {
    this.startTon();
  },
  methods: {
    startTon() {
      if (window?.tonkeeper) {
        this.installTon = true;
        this.providerTon = window.tonkeeper;

        // Вызываем хуки внутри метода
        const { tonConnectUI } = useTonConnectUI();
        this.tonConnectUI = tonConnectUI;

        // Получаем данные через хуки
        this.connectedWalletTon = useTonWallet();
      }
      else {
        console.log("Tonkeeper не установлен.");
      }
    },
    async connectTon() {
      try {
        const connectedWallet = await this.tonConnectUI.connectWallet();
        this.connectedWalletTon = connectedWallet;
      }
      catch (error) {
        console.error("Error connecting to TonKeeper:", error);
      }
    },

    async getBalanceTon(address) {
      let url = "";
      if (this.connectedWalletTon?.account?.chain == "-3") {
        // "-3" тестовая сеть, но с 01.12.24 не открыватеся
        url = "https://testnet.toncenter.com/api/v3/walletInformation?address=";
      }
      else {
        // "-239" реальная сеть
        url = "https://tonapi.io/v2/accounts/";
      }
      const balanceResponse = await fetch(
        `${url}${address}`
      );
      const balanceData = await balanceResponse.json();
      this.convertTonsValue(balanceData?.balance);
    },

    async disconnectTon() {
      try {
        await this.tonConnectUI.disconnect();
        this.connectedWalletTon = null;
      }
      catch (error) {
        console.log(error);
      }
    },
    convertTonsValue(balance) {
      const parsedData = Math.floor((Number(balance) / 1000000000) * 100) / 100;
      const [wholePart, decimalPart] = String(parsedData).split(".");
      this.balanceWalletTon = parsedData;

      return {
        wholePart,
        decimalPart,
        fullPart: parsedData
      };
    },
    async testTon() {
      // console.log("tonChain", CHAIN); // вернет сеть
      // const connector = new TonConnect();
      // const walletsList1 = await connector.getWallets(); // список всех кошельков
      // const walletsList2 = await this.tonConnectUI.getWallets(); // список всех кошельков

      // await this.tonConnectUI.openModal(); // открывает модалку и сразу коннектит Баг
      // await this.tonConnectUI.openSingleWalletModal("tonkeeper"); // открывает модалку по манифесту tonkeeper
      // await this.tonConnectUI.closeSingleWalletModal(); // закрывает модалку
      // await this.tonConnectUI.modalState; // статус модалки

      // Еще данные
      // const currentWallet = this.tonConnectUI.wallet;
      // const currentWalletInfo = this.tonConnectUI.walletInfo;
      // const currentAccount = this.tonConnectUI.account;
      // const currentIsConnectedStatus = this.tonConnectUI.connected;
      // console.log("currentWallet", currentWallet);
      // console.log("currentWalletInfo", currentWalletInfo);
      // console.log("currentAccount", currentAccount);
      // console.log("currentIsConnectedStatus", currentIsConnectedStatus);
    }
  }
};
