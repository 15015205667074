<template>
  <div>
    <div class="fs22">
      {{ $t("incomeChart.title") }}
    </div>
    <div class="i-control">
      <div class="i-indicator">
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="isBalanceActive ? 'link' : ''"
          @click="isBalanceActive=!isBalanceActive;changeSeries()"
        >
          Balance
        </AuButton>
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="!isBalanceActive ? 'link' : ''"
          @click="isBalanceActive=!isBalanceActive;changeSeries()"
        >
          Profit
        </AuButton>
      </div>
      <div class="i-indicator">
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="activePeriod === 0 ? 'link' : ''"
          @click="activePeriod = 0;changeSeries()"
        >
          1D
        </AuButton>
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="activePeriod === 1 ? 'link' : ''"
          @click="activePeriod = 1;changeSeries()"
        >
          1M
        </AuButton>
        <AuButton
          :bordered="false"
          font-color="#636363"
          font-size="13px"
          height="28px"
          padding-horizontal="10px"
          :type="activePeriod === 2 ? 'link' : ''"
          @click="activePeriod = 2;changeSeries()"
        >
          1W
        </AuButton>
      </div>
    </div>
    <Apexchart
      :series="series"
      :options="options"
      height="200px"
    />
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "IncomeChart",
  data() {
    return {
      isBalanceActive: true,
      activePeriod: 0,
      series: [{
        data: [2500, 2131, 2700, 1945, 1800, 1763],
        color: "#28E593"
      }]
    };
  },
  computed: {
    options() {
      return {
        chart: {
          type: "area",
          toolbar: {
            show: false
          }
        },
        stroke: {
          curve: "smooth"
        },
        fill: {
          type: "gradient",
          colors: ["#28E593"],
          gradient: {
            opacityFrom: 0.7,
            opacityTo: 0.1
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false,
          row: {
            colors: ["#28E5931A"],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"]
        },
        yaxis: {
          labels: {
            formatter: (v, _) => {
              if (this.isBalanceActive) {
                return `${v} USDT`;
              }
              return `${v}%`;
            }
          }
        }
      };
    }
  },
  methods: {
    changeSeries() {
      const initArray = this.isBalanceActive ?
        [2500, 2131, 2700, 1945, 1800, 1763] : [7, 10, 50, 40, 30, 60];
      const max = this.isBalanceActive ? 2000 : 10;
      this.series[0].data = _.map(initArray, el => {
        const delta = Math.floor(Math.random() * max);
        return Math.floor(Math.random() * 2) === 0 ? el + delta : el - delta;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.i-control {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.i-indicator {
  display: flex;
  gap: 10px;
}
</style>