<template>
  <div
    class="left-panel panel"
    :light="isLightTheme"
  >
    <div
      class="logo-panel"
      :light="isLightTheme"
    >
      <a :href="path">
        <img
          v-if="showDarkLogo"
          :src="`/svg/dark/${mainLogo}.svg`"
          alt=""
        >
        <AuIcon
          v-else
          icon="logo"
        />
      </a>
      <div
        v-if="showTestnet"
        class="tm"
      >
        <div class="testnet-title">
          Testnet
        </div>
      </div>
    </div>

    <div class="left-panel__panel">
      <Menu />
    </div>
    <Briefcase v-if="stage" />
  </div>
</template>

<script>
import _ from "lodash";

import Briefcase from "./components/Briefcase";
import Menu from "@/components/menu/Menu.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Briefcase,
    Menu
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      ethAddresses: "ethAddresses",
      auOwners: "auOwners"
    }),

    mainLogo() {
      return process.env.VUE_APP_AUT_LOGO || "logo.v2";
    },

    stage() {
      if (!this.address || !this.address.verifiedAt || this.address.status == "add" ||
          this.$auth.user().type == "issuer" || this.$route.meta?.hideMyAsset) {
        return false;
      }
      return true;
    },

    address() {
      return _.get(this.ethAddresses, "[0]", "");
    },

    showDarkLogo() {
      return window.screen.availWidth > 1024;
    },

    path() {
      let parent = _.find(this.$route.matched, (match) => match.meta.isMain);
      return _.get(parent, "path");
    },

    showTestnet() {
      return process.env.VUE_APP_SHOW_TESTNET == 1;
    }
  },

  methods: {
    setRoute(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style scoped lang="scss">
.tm {
  height: 32px;
  margin-left: -4px;
}
.testnet-title {
  font-size: 12px;
  color: #FFFFFF80;
}

.left-panel {
  height: 100vw;
  width: 275px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  border-right: 1px solid var(--color-90);

  &[light=true] {
    background-color: #1C2434;
  }

  @media screen and (max-width: 1200px) {
    width: 235px;
  }

  &__panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__card {
    width: 160px;
    height: 180px;

    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-radius: 13px;

    &-chard {
      margin-top: 8px;
    }

    &-value {
      position: absolute;
      top: 47px;
      font-size: 15px;
    }
  }

  &__help {
    margin-bottom: 96px;
  }
}
.logo-panel {
  width: 100%;
  height: 67px;
  padding-left: 10%;

  border-bottom: 1px solid var(--color-90);
  display: flex;
  align-items: center;
  gap: 9px;

  &[light=true] {
    border-bottom: 1px solid #474749;
  }

  img {
    height: 35px;
    width: 180px;
  }
}
</style>
