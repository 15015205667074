<template>
  <div>
    <AuBreadcrumb
      :parent-name="$t('router.pages.dashboard')"
    />
    <RouterView v-if="isCompanyManager" />
    <div v-else>
      <div class="companies-info card">
        <div class="form-header">
          <div>
            <div class="title">
              {{ $t('companies.label') }}
            </div>
            <div
              v-if="!loading"
              class="d-flex justify-content-between subtitle-line"
            >
              <span class="explain">
                {{ $t('companies.companiesCount') }}:
                <span class="count">
                  {{ items.length === 0 ? "0" : items.length }}
                </span>
              </span>
            </div>
          </div>
          <div>
            <div class="btn_indent">
              <AuButton
                bordered
                center
                type="primary"
                width="125px"
                @click="showCompanyManager(null)"
              >
                <span class="btn-text">
                  {{ $t('companies.addCompanyButton') }}
                </span>
              </AuButton>
            </div>
          </div>
        </div>
        <AuTable
          class="companies-table"
          :columns="columns"
          :items="items"
          :action-column="actionColumn"
          action-type="company"
          :icons="null"
          :loading="loading"
          @del="showDeleteDialog"
          @edit="showCompanyManager"
        />
        <div class="companies-accordion">
          <AuAccordion
            v-for="(company, index) in items"
            :key="company"
            :last-child="(index == items.length - 1)"
          >
            <template #title>
              <span class="company-header">{{ company.name }}</span>
            </template>
            <template #content>
              <div class="company-info container-fluid">
                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('companies.secondColumn') }}</span>
                  </div>
                  <div class="col">
                    <span>{{ company.country }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col info-title">
                    <span>Верификация</span>
                  </div>
                  <div class="col">
                    <span>{{ company.isVerified }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col info-title">
                    <span>{{ $t('companies.fourthColumn') }}</span>
                  </div>
                  <div class="col">
                    <span>{{ company.isVerifyRequest }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col buttons">
                    <AuButton
                      width="100%"
                      center
                      @click="showCompanyManager(index)"
                    >
                      {{ $t("counterparties.editAction") }}
                    </AuButton>
                    <AuButton
                      width="100%"
                      center
                      @click="showDeleteDialog(index)"
                    >
                      {{ $t("counterparties.deleteAction") }}
                    </AuButton>
                  </div>
                </div>
              </div>
            </template>
          </AuAccordion>
        </div>
      </div>
      <AuModal
        v-model="deleteDialog"
        width="500px"
      >
        <template #header>
          <span class="modal_head_text">
            {{ $t('companies.deleteDialog.title') }}
          </span>
        </template>
        <template #body>
          <span>
            {{ $t('companies.deleteDialog.subtitle') }} - {{ deleteName }}?
          </span>
        </template>
        <template #footer>
          <div class="modal_button">
            <div class="modal_button_margin">
              <AuButton
                bordered
                center
                width="80px"
                @click="del"
              >
                {{ $t('companies.deleteDialog.agree') }}
              </AuButton>
            </div>
            <div class="modal_button_margin">
              <AuButton
                bordered
                center
                width="80px"
                @click="deleteDialog=false"
              >
                {{ $t('companies.deleteDialog.cancel') }}
              </AuButton>
            </div>
          </div>
        </template>
      </AuModal>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import i18n from "@/localization/localization.js";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      columns: [{ name: this.$t("companies.firstColumn"), align: "start" },
        { name: this.$t("companies.secondColumn") },
        { name: this.$t("companies.thirdColumn") },
        { name: this.$t("companies.fourthColumn") },
        { name: this.$t("companies.fifthColumn") }],
      deleteDialog: false,
      deleteId: null,
      deleteIndex: null,
      deleteName: null,
      actionColumn: { label: this.$t("companies.fifthColumn"), align: "center", icon: "more" },
      loading: true
    };
  },

  computed: {
    ...mapGetters({
      companies: "companies",
      country: "country"
    }),

    isCompanyManager() {
      return _.includes(this.$route.path, "company-manager");
    },

    items() {
      return _.map(this.companies, company => ({
        name: i18n.global.locale == "ru" ? company.nameRus : company.nameEng,
        country: _.chain(this.countryOptions).find({ value: company.countryCode }).get("display").value(),
        description: i18n.global.locale == "ru" ? company.descriptionRus : company.descriptionEng,
        isVerifyRequest: company.isVerified ? this.$t("companies.companyVerified") :
          (company.isVerifyRequest ? this.$t("companies.companyNotVerified") :
            this.$t("companies.companyIsDraft"))
      }));
    },

    countryOptions() {
      return _.map(this.country, c => ({ display: c.name, value: c.code }));
    }
  },

  async mounted() {
    if (this.$auth.user().type != "issuer") {
      this.$router.push({ name: "base" });
    }
    if (_.isEmpty(this.country)) {
      await this.getCountry();
    }
    if (_.isEmpty(this.companies)) {
      await this.getCompanies();
    }

    this.loading = false;
  },

  methods: {
    ...mapActions({
      getCompanies: "getCompanies",
      deleteCompany: "deleteCompany",
      getCountry: "getCountry"
    }),

    showCompanyManager(id) {
      if (id !== null && this.companies[id].isVerifyRequest) {
        this.showError(this.$t("toasts.error.companyEdit"));
        return;
      }
      this.$router.push({ name: "company manager", params: { id: _.isNull(id) ? id : this.companies[id].id } });
    },

    showDeleteDialog(index) {
      this.deleteId = this.companies[index].id;
      this.deleteName = this.companies[index].nameRus;
      this.deleteIndex = index;
      this.deleteDialog = true;
    },

    async del() {
      this.deleteDialog = false;
      try {
        await this.deleteCompany(this.deleteId);
        this.companies.splice(this.deleteIndex, 1);
        this.showSuccess(this.$t("toasts.success.companyDelete"));
      }
      catch (e) {
        this.showError(this.$t("toasts.error.companyDelete"));
      }
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    }
  }
};
</script>
<style lang="scss" scoped>
.companies {
  &-info {
    padding: 20px;
    .title {
      font-size: 24px;
      color: var(--text-color);
      line-height: 1.1;
    }
    .explain {
      font-weight: 450;
      font-size: 12px;
      color: var(--color-60);
      .count{
        font-weight: 500;
        font-size: 14px;
        color: var(--color-60);;
      }
    }
    .form-header {
      margin-bottom: 20px;
    }
  }

  &-count {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 500;
    font-size: 15px;
    color: var(--text-color);
    margin-bottom: 40px;
    span {
      color: var(--color-60);
      font-weight: 500;
      font-size: 11px;
    }
  }

  &-table {
    @media screen and (max-width:500px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:500px) {
      display: block;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.col {
  padding: 0;
  font-size: 12px;
  &:not(.info-title) {
    text-align: center;
  }
}

.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }
  &-info {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    gap: 20px
  }
}
.modal_head_text {
  font-weight: 450;
  font-size: 20px;
}
.btn_indent{
  display: flex;
  justify-content: end;
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.btn-text{
  font-weight: 450;
  font-size: 12px;
}
.companies-table {
  margin-bottom: 0;
}
</style>