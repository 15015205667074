<template>
  <div>
    <div class="t-header">
      <span class="fs22">
        {{ $t("transactionsHistory.title") }}
      </span>
      <RouterLink
        class="t-link"
        to="/transactions"
      >
        {{ $t("transactionsHistory.allTransactions") }}
      </RouterLink>
    </div>
    <div v-if="walletVerified">
      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div
        v-else
        class="t-content"
      >
        <div
          v-if="period.length > 0"
          class="t-period"
        >
          {{ period }}
        </div>
        <LastTransaction
          v-for="(transaction, index) in transactions"
          :key="index"
          v-bind="transaction"
        />
      </div>
    </div>
    <div v-else>
      <span>
        {{ $t("transactionsHistory.notVerified1") }}
        <a
          class="t-metamask"
          href="/security"
        >
          {{ $t("transactionsHistory.notVerified2") }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import LastTransaction from "@/components/lastTransactions/LastTransaction";
import moment from "moment/moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TransactionsHistory",
  components: {
    LastTransaction,
    ProgressLoader
  },
  data() {
    return {
      loading: true,
      addressEth: null,
      userIRI: "",
      transactions: []
    };
  },
  computed: {
    ...mapGetters({
      ethAddresses: "ethAddresses"
    }),

    walletVerified() {
      return _.find(this.ethAddresses, eth => eth?.verifiedAt);
    },

    period() {
      if (!this.transactions.length) {
        return this.$t("transactionsHistory.none");
      }
      else {
        if (this.transactions.length && moment(this.transactions[0]?.createdAt).format("DD.MM.YYYY") == moment().format("DD.MM.YYYY")) {
          return this.$t("transactionsHistory.today");
        }
        else if (
          moment(this.transactions[0]?.createdAt).format("DD.MM.YYYY") == moment().subtract(1, "days").format("DD.MM.YYYY")
        ) {
          return this.$t("transactionsHistory.yesterday");
        }
        else {
          return "";
        }
      }
    }
  },

  async mounted() {
    this.userIRI = `/api/users/${this.$auth.user().id}`;
    await this.getTransactions();
  },

  methods: {
    ...mapActions({
      getTransactionsPage: "getTransactionsPage"
    }),

    async getTransactions() {
      this.loading = true;
      const resp = await this.getTransactionsPage({
        pageId: 1,
        itemsPerPage: 3,
        sort: "desc",
        userId: this.$auth.user().id
      });
      const data = resp?.data;
      if (!data) {
        this.loading = false;
        return;
      }

      _.forEach(data, transaction => {
        if (!transaction?.auToken) {
          this.loading = false;
          return;
        }

        _.forEach(this.ethAddresses, eth => {
          if (eth.value.toLowerCase() === transaction.addressFrom.toLowerCase()) {
            this.addressEth = transaction.addressTo;
          }
          if (eth.value.toLowerCase() === transaction.addressTo.toLowerCase()) {
            this.addressEth = transaction.addressFrom;
          }
          if (this.userIRI == _.toLower(transaction?.fromUser)) {
            this.addressEth = transaction?.addressFrom;
          }
          if (transaction.addressFrom == 0) {
            this.addressEth = transaction.addressTo;
          }
        });
        this.transactions.push({
          icon: transaction?.auToken?.logoMain ?? transaction?.auToken?.mainImage?.name,
          name: this?.addressEth ? this.addressEth.slice(0, 4) + "..." + this.addressEth.slice(-4) : "",
          addressFrom: transaction.addressFrom,
          addressTo: transaction.addressTo,
          addressEth: this.addressEth,
          amount: transaction.amount,
          isConfirmed: transaction.isConfirmed,
          cardType: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
          isRefill: this.ethAddresses[0]?.value.toLowerCase() == transaction?.addressTo.toLowerCase(),
          transactionType: transaction?.methodName,
          decimals: transaction?.auToken?.decimals,
          mnemonic: transaction?.auToken?.mnemonicEng,
          createdAt: transaction.createdAt,
          hash: transaction.hash,
          projectId: transaction?.auCapital?.id ?? null,
          projectName: transaction?.auCapital?.tokenBase?.nameEng ?? "",
          isVoting: transaction?.auCapital?.projectType === "voting"
        });
      });
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.t-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.t-link {
  color: #279063;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}
.t-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.t-period {
  font-size: 12px;
}
.t-metamask {
  color: var(--text-color);
  text-decoration: underline;
}
</style>