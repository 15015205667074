<template>
  <div>
    <AuBreadcrumb
      :parent-name="$t('router.pages.dashboard')"
    />

    <ProgressLoader
      v-if="dataLoading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />

    <div
      v-if="!dataLoading"
      class="form-container"
    >
      <div class="fieldset">
        <div class="email-info card">
          <div class="card__header">
            <div class="title">
              {{ $t("Email") }}
            </div>
          </div>
          <AuInput
            v-if="userProfile.email"
            v-model="userProfile.email"
            :icon-end="profile?.isConfirmed ? 'check_green' : ''"
            readonly
            border-radius="8"
            :class="{'mb-3': !profile?.isConfirmed}"
            :placeholder="userProfile?.email || 'Email'"
          />
          <div>
            <div
              v-if="messageText"
              :style="cssVars"
              class="status"
            >
              {{ messageText }}
            </div>
            <AuButton
              v-if="!profile?.isConfirmed"
              type="primary"
              width="100%"
              class="mb-3"
              center
              :is-progress="progressBtn"
              @click="confirmEmail"
            >
              {{ btnText }}
            </AuButton>
          </div>
        </div>

        <div class="card">
          <div class="card__header">
            <div class="title">
              {{ $t("profile.profile") }}
            </div>
          </div>
          <AuInput
            v-model="userProfile.fName"
            :readonly="userProfile?.kycStatus"
            :icon-end="verifiedIcon"
            :label="$t('profile.fName')"
            border-radius="8"
            class="mb-3"
            :placeholder="$t('profile.fName')"
          />
          <AuInput
            v-model="userProfile.iName"
            :readonly="userProfile?.kycStatus"
            :icon-end="verifiedIcon"
            :label="$t('profile.iName')"
            border-radius="8"
            class="mb-3"
            :placeholder="$t('profile.iName')"
          />
          <AuInput
            v-if="userProfile.phone"
            v-model="userProfile.phone"
            :label="$t('profile.phone')"
            readonly
            border-radius="8"
            class="mb-3"
            :placeholder="userProfile?.phone || '+7 000 000 00 00'"
          />
          <div
            v-if="userProfile?.kycStatus"
          >
            {{ kycNode }}
          </div>

          <AuButton
            v-if="!userProfile?.kycStatus"
            type="primary"
            width="100%"
            class="mt-4"
            center
            :is-progress="progress"
            @click="saveProfile"
          >
            {{ $t('profile.save') }}
          </AuButton>
        </div>
      </div>
      <div class="fieldset">
        <div class="kyc-info card">
          <div class="card__header">
            <div class="title">
              {{ $t("KYC") }}
            </div>
          </div>
          <div class="centered">
            <img
              class="kyc-icon"
              :style="iconStyle"
              :src="kycIcon"
              alt=""
            >
            <div
              v-if="!profile?.isConfirmed"
              class="mt-4"
            >
              {{ $t('profile.kycInfo1') }}
            </div>
            <AuButton
              v-if="showButton"
              border-radius="10"
              font-color="#2ABA7C"
              width="216px"
              class="mt-3"
              center
              @click="goToKYC"
            >
              {{ textButton }}
            </AuButton>
          </div>
        </div>
      </div>
    </div>
    <AuModal
      v-model="modifyDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          {{ $t('profile.kycInfo2') }}
        </span>
      </template>
      <template #body>
        <span>
          {{ $t('profile.kycInfo3') }}
        </span>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="125px"
              @click="modifyDialog=false"
            >
              {{ $t('projects.dialog.cancel') }}
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              :type="'primary'"
              width="125px"
              @click="$router.push({ name: 'kyc' })"
            >
              {{ $t('Ok') }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "User",
  components: {
    ProgressLoader
  },
  data() {
    return {
      userProfile: {
        fName: "",
        iName: "",
        phone: "",
        email: ""
      },
      progress: false,
      progressBtn: false,
      btnText: "Send confirmation email",
      status: "wait",
      errorMsg: "",
      modifyDialog: false
    };
  },
  computed: {
    ...mapGetters({
      dataLoading: "profile/dataLoading",
      profile: "profile/profile"
    }),

    kycNode() {
      if (this.userProfile?.kycStatus == "checking") {
        return this.$t("profile.kycInfo4");
      }
      else if (this.userProfile?.kycStatus == "completed") {
        return this.$t("profile.kycInfo5");
      }
      else if (this.userProfile?.kycStatus == "declined") {
        return this.$t("profile.kycInfo6");
      }

      return "";
    },

    cssVars() {
      return {
        "color": this.status == "wait" || this.status == "error" ? "#ba2a2a" : "#2aba7c"
      };
    },

    iconStyle() {
      return {
        "filter": this.profile?.isConfirmed ? "" : "grayscale(100%)"
      };
    },

    showButton() {
      return this.userProfile?.kycStatus !== "completed" && this.profile?.isConfirmed;
    },

    textButton() {
      return this.userProfile?.kycStatus ? this.$t("profile.kycComplete") : this.$t("profile.kycStart");
    },

    reSent() {
      return this.userProfile?.kycStatus !== "completed";
    },

    messageText() {
      if (this.profile?.isConfirmed) {
        return "";
      }
      switch (this.status) {
        case "wait":
          return this.$t("profile.textInfo1");
        case "confirmed":
          return this.errorMsg;
        case "ok":
          return this.$t("profile.textInfo2");
        case "error":
          return this.errorMsg;
        default:
          return "";
      }
    },

    kycIcon() {
      let ret = "wait_verified.svg";
      const status = this.userProfile?.kycStatus;
      if (!status) {
        ret = "go_to_verified.svg";
      }
      if (status === "declined") {
        ret = "not_verified.png";
      }
      if (status === "completed") {
        ret = "verified.svg";
      }
      return `/img/kyc/${ret}`;
    },

    verifiedIcon() {
      if (this.userProfile?.kycStatus == "completed") {
        return "check_green";
      }
      return "";
    }
  },

  async mounted() {
    await this.getProfile(this.$auth.user().id);
    this.userProfile = await this.profile;
    if (this.userProfile?.isConfirmed) {
      this.status = "ok";
    }
  },

  methods: {
    ...mapActions({
      dataLoading: "profile/dataLoading",
      getProfile: "profile/getProfile",
      editProfile: "profile/editProfile"
    }),

    async saveProfile() {
      this.progress = true;
      await this.editProfile(this.userProfile);
      this.progress = false;
    },

    async confirmEmail() {
      this.progressBtn = true;
      const url = "/api/users/send_account_confirm_code";
      try {
        const resp = await this.axios.get(url);

        if (resp.data?.status == "ok") {
          this.status = "ok";
        }
        if (resp.data?.code == 267 && resp.data?.message == "Already confirmed!") {
          this.status = "confirmed";
          this.errorMsg = resp.data?.message;
          this.userProfile = await this.profile;
        }
        if (resp.data?.code == 267 && resp.data?.message != "Already confirmed!") {
          this.status = "error";
          this.errorMsg = resp.data?.message;
        }
      }
      catch (error) {
        this.status = "error";
      }
      finally {
        this.btnText = "Resend confirmation email";
        this.progressBtn = false;
      }
    },

    goToKYC() {
      if (this.userProfile?.kycStatus) {
        this.$router.push({ name: "kyc" });
      }
      else {
        this.modifyDialog = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  padding: 15px;
}

.card__header {
  display: flex;
  flex-direction: column;
  gap: 40px;

  border-bottom: 1px solid var(--color-70);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.title {
  color: var(--text-color);
  font-size: 24px;
  line-height: 1em;
  text-align: left;
}
.profile {
  display: flex;
}
.person-info {
  color: var(--color-20);
}
.form-container {
  display: flex;
  gap: 15px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.fieldset {
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  .btn-save {
    margin-top: 30px;
    width: 100%;
  }
}
.kyc-info {
  height: 100%;

  display: flex;
  flex-direction: column;

  .centered {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .kyc-icon {
    width: 216px;
  }
}
.person-fio {
  margin-right: 6px;
}
.data-in {
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 15px;
  margin-bottom: 10px;

  &::placeholder {
    color: var(--color-50);
  }
}

.status {
  font-size: 14px;
  margin-bottom: 8px;
  text-align: center;
}
</style>