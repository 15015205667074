<template>
  <div>
    <AuBreadcrumb :pages="breadcrumb" />
    <div class="card">
      <!-- Title -->
      <!-- Ton not install -->
      <div
        v-if="!installTon"
        class="title"
      >
        {{ $t("tonWalletVerification.title_0") }}
      </div>

      <!-- Ton install -->
      <div v-if="installTon">
        <div
          v-if="!isTonValue?.status"
          class="title"
        >
          <!-- Подключите Ton кошелек и подтвердите адрес -->
          {{ $t("tonWalletVerification.title_1") }}
        </div>
        <div
          v-if="checkVerifySuccess || isTonValue?.status"
          class="title"
        >
          <!-- Поздравляем! Вы верифицировали адрес! -->
          {{ $t("tonWalletVerification.title_2") }}
        </div>
      </div>

      <!-- Alerts -->
      <div class="alert-block">
        <div
          v-if="arrTon[0]?.value && !isTonValue?.value && userAddressTon"
          class="alert alert-secondary"
          role="alert"
        >
          <div>
            {{ $t("tonWalletVerification.alert_1") }}
          </div>
          <div>
            {{ $t("tonWalletVerification.alert_2") }} {{ arrTon[0]?.value }}
          </div>
          <div>
            {{ $t("tonWalletVerification.alert_3") }} {{ userAddressTon }}
          </div>
        </div>

        <!-- Адрес Ton уже существует в БД -->
        <div
          v-if="userAddressTon && tonExists"
          class="alert alert-danger"
          role="alert"
        >
          <div>
            <!-- Этот кошелек уже существует -->
            {{ $t("tonWalletVerification.alert_4") }} {{ userAddressTon }}
          </div>
        </div>
      </div>

      <!-- Descriptions -->
      <!-- Не установлен Ton -->
      <div
        v-if="!installTon"
        class="subtitle"
      >
        <div>
          <!-- Не удается найти установленное расширение Ton кошелек -->
          {{ $t("tonWalletVerification.subtitle_1") }}
        </div>
        <div
          class="mt-2"
        >
          {{ $t("tonWalletVerification.subtitle_2") }}
        </div>

        <a
          class="button-link"
          href="https://tonkeeper.com/"
          target="_blank"
        >
          <AuButton
            v-if="!installTon"
            class="mt-4"
            type="primary"
            center
            width="270px"
          >
            <div class="btn_tonkeeper">
              <div
                class="tonkeeper"
              />
              <div>
                {{ $t("tonWalletVerification.btn_1") }}
              </div>
            </div>
          </AuButton>
        </a>
      </div>

      <!-- Если Ton установлен -->
      <div
        v-if="installTon"
        class="subtitle"
      >
        <!-- Установлен - Не подключен -->
        <div
          v-if="!userAddressTon && !checkVerifySuccess"
          class="subtitle"
        >
          <div>
            {{ $t("tonWalletVerification.subtitle_3") }}
          </div>
          <div
            class="mt-2"
          >
            {{ $t("tonWalletVerification.subtitle_4") }}
          </div>
          <AuButton
            :disabled="statusConnection"
            class="mt-4"
            type="primary"
            center
            width="270px"
            @click="connectTon"
          >
            <div class="btn_tonkeeper">
              <div
                class="tonkeeper"
              />
              <div>
                {{ $t('tonWalletVerification.btn_2') }}
              </div>
            </div>
          </AuButton>
        </div>

        <!-- Установлен - Не подключен - Нет в БД -->
        <div v-if="arrTon[0]?.value && !isTonValue?.value && userAddressTon">
          <div class="mt-2">
            {{ $t("tonWalletVerification.subtitle_16") }} {{ arrTon[0]?.value }}
          </div>
          <div>
            {{ $t("tonWalletVerification.subtitle_17") }}
          </div>
        </div>
        <div
          v-if="!arrTon[0]?.value && !isTonValue?.value &&userAddressTon && (userAddressTon !== isTonValue?.value) && !tonExists && connectedWalletTon && !checkVerifySuccess"
          class="subtitle"
        >
          <div class="mt-2">
            {{ $t("tonWalletVerification.subtitle_11_1") }} {{ userAddressTon }} {{ $t("tonWalletVerification.subtitle_11_2") }}
          </div>
          <div class="mt-2">
            {{ $t("tonWalletVerification.subtitle_12") }}
          </div>

          <AuButton
            :disabled="statusSign"
            class="mt-4"
            type="primary"
            center
            width="270px"
            @click="startVerifyTon"
          >
            <div class="btn_tonkeeper">
              <div
                class="tonkeeper"
              />
              <div>
                {{ $t('tonWalletVerification.btn_4') }}
              </div>
            </div>
          </AuButton>
        </div>

        <!-- Установлен - Подключен - Сохранен Кошелек в БД - НЕ совпадают -->
        <div
          v-if="isTonValue?.value && userAddressTon && (userAddressTon !== isTonValue?.value) && !tonExists && connectedWalletTon"
          class="subtitle"
        >
          <div>
            {{ $t("tonWalletVerification.subtitle_7_1") }} {{ userAddressTon }} {{ $t("tonWalletVerification.subtitle_7_2") }}
          </div>
          <div
            class="mt-4"
          >
            {{ $t("tonWalletVerification.subtitle_8") }}
          </div>
          <div
            class="mt-2"
          >
            {{ $t("tonWalletVerification.subtitle_9") }}
          </div>
          <div>
            {{ $t("tonWalletVerification.subtitleNote") }}
          </div>
          <AuButton
            :disabled="statusChange"
            class="mt-4"
            type="primary"
            center
            width="270px"
            @click="openTon"
          >
            <div class="btn_tonkeeper">
              <div class="tonkeeper" />
              <div>
                {{ $t('tonWalletVerification.btn_3') }}
              </div>
            </div>
          </AuButton>
        </div>

        <!-- Установлен - Подключен - Сохранен Кошелек в БД - Совпадают  -->
        <div
          v-if="(userAddressTon === isTonValue?.value) && !tonExists && !checkVerifySuccess && userAddressTon && !isTonValue?.status"
          class="subtitle"
        >
          <div>
            {{ $t("tonWalletVerification.subtitle_11_1") }} {{ userAddressTon }} {{ $t("tonWalletVerification.subtitle_11_2") }}
          </div>

          <div
            v-if="!statusSign"
            class="mt-2"
          >
            {{ $t("tonWalletVerification.subtitle_12") }}
          </div>
          <div
            v-if="statusSign"
            class="mt-4"
          >
            {{ $t("tonWalletVerification.subtitle_13") }}
          </div>
          <div
            v-if="statusSign"
          >
            {{ $t("tonWalletVerification.subtitleNote") }}
          </div>
          <AuButton
            :disabled="statusSign"
            class="mt-4"
            type="primary"
            center
            width="270px"
            @click="startVerifyTon"
          >
            <div class="btn_tonkeeper">
              <div
                class="tonkeeper"
              />
              <div>
                {{ $t('tonWalletVerification.btn_4') }}
              </div>
            </div>
          </AuButton>
        </div>

        <!-- Верифицирован -->
        <div v-if="checkVerifySuccess || isTonValue?.status">
          <div class="subtitle">
            {{ $t("tonWalletVerification.subtitle_14") }}
          </div>
          <div class="subtitle">
            {{ $t(`tonWalletVerification.subtitle_15${isEqual}`) }}
          </div>
          <div class="d-flex align-items-center mt-4">
            <AuButton
              center
              width="200px"
              @click="gotoPage(isIssuer ? 'buy form' : 'projects')"
            >
              {{ leftButtonText }}
            </AuButton>
            <AuButton
              class="ms-2"
              center
              type="primary"
              width="200px"
              @click="gotoCreate"
            >
              {{ rightButtonText }}
            </AuButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuButton from "@/components/extended/AuButton.vue";
import TonKeeper from "@/mixins/TonKeeper";
import { mapActions, mapGetters } from "vuex";
import { useTonAddress } from "@townsquarelabs/ui-vue";

export default {
  name: "TonVerification",
  components: {
    AuButton
  },

  mixins: [TonKeeper],

  setup() {
    const userAddressTon = useTonAddress();
    const rawAddressTon = useTonAddress(false);

    return {
      userAddressTon,
      rawAddressTon
    };
  },

  data() {
    return {
      tonExists: false,
      statusSign: false,
      breadcrumb: [
        { key: 0, name: this.$t("router.pages.dashboard"), path: "/" }
      ]
    };
  },

  computed: {
    ...mapGetters({
      arrTon: "tonAddresses",
      companyCount: "companyCount"
    }),


    appTitle() {
      return process.env.VUE_APP_TITLE;
    },

    checkVerifySuccess() {
      return this.isTonValue?.value && this.isTonValue?.status && this.isTonValue?.verifiedAt;
    },

    checkChange() {
      return (this.userAddressTon && this.isTonValue?.value && (this.userAddressTon != this.isTonValue?.value)) || this.tonExists;
    },

    isIssuer() {
      return this.$auth.user().type == "issuer";
    },

    leftButtonText() {
      return this.$t("tonWalletVerification.btn_" + (this.isIssuer ? "6" : "8"));
    },

    rightButtonText() {
      return this.$t("tonWalletVerification.btn_" + (this.isIssuer ? (this.companyCount > 0 ? "5" : "7") : "6"));
    },

    isEqual() {
      return /equal/i.test(process.env.VUE_APP_TITLE) ? "Equal" : "";
    },

    isTonValue() {
      const tonValue = this.arrTon.find(item => item.value === this.userAddressTon);
      return tonValue || "";
    }
  },

  watch: {
    userAddressTon() {
      this.tonExists = false;
      this.btnSignIn = true;
    }
  },

  methods: {
    ...mapActions({
      postTonAddresses: "postTonAddresses",
      getTonAddresses: "getTonAddresses",
      getValidateContentTon: "getValidateContentTon",
      patchTonAddresses: "patchTonAddresses"
    }),

    refresh() {
      window.location.reload();
    },

    gotoPage(url) {
      this.$router.push({ name: url });
    },
    gotoCreate() {
      let url = "/briefcase/buy-form";
      if (this.isIssuer) {
        url = this.companyCount > 0 ? "/my-projects/edit-project/new" : "/companies/company-manager";
      }
      this.$router.push(url);
    },

    sendCarrot(address) {
      if (window.carrotquest && process.env.VUE_APP_CARROT_ENABLED === "true") {
        window.carrotquest.track("ton assigned", {
          "address": address
        });
        window.carrotquest.identify([
          { op: "update_or_create", key: "tonWallet", value: address }
        ]);
      }
    },

    async addTon() {
      if (!this.userAddressTon) {
        return;
      }
      const id = this.$auth.user().id;
      try {
        const resp = await this.postTonAddresses({
          value: this.userAddressTon,
          user: `/api/users/${id}`
        });
        if (resp?.data.message == "Address already exists!") {
          this.$toast.warning(resp?.data.message);
          this.tonExists = true;
          this.statusSign = false;
        }
        else {
          await this.getTonAddresses();
          this.tonExists = false;

          await this.addToRegistryTon(this.isTonValue?.id);
          this.sendCarrot(this.isTonValue?.value);
        }
      }
      catch (error) {
        console.log("Error_addTon", error);
        this.statusSign = false;
        await this.getTonAddresses();
        this.$toast.error(error?.message);
      }
    },

    startVerifyTon() {
      this.statusSign = true;
      if (this.isTonValue?.value && (this.userAddressTon === this.isTonValue?.value)) {
        this.addToRegistryTon(this.isTonValue?.id);
      }
      else {
        this.addTon();
      }
      this.statusSign = false;
    },

    async addToRegistryTon(id) {
      let body = {
        status: "add"
      };

      try {
        await this.patchTonAddresses({ id: id, body: body });
        await this.getTonAddresses();
        this.statusSign = false;
        this.$toast.success(this.$t("validate.success"));
      }
      catch (error) {
        await this.getTonAddresses();
        this.statusSign = false;
        console.error("Error_addToRegistry", error);
      }
    }
  }

};
</script>

<style scoped lang="scss">

.card {
  padding: 20px;
  border-radius: 12px;
}

.title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 14px;
}

.btn_tonkeeper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tonkeeper {
  background: url(../assets/img/tonkeeper_big.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  padding: 8px;
  margin-right: 10px;
}
.link {
  color: #ffffff;
}
.button-link {
  display: inline-block;
  text-decoration: none;
}
</style>