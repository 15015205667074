<template>
  <div class="wv-main">
    <TonVerification />
  </div>
</template>

<script>
import TonVerification from "@/components/TonVerification";

export default {
  name: "TonWalletVerification",
  components: {
    TonVerification
  }
};
</script>

<style scoped lang="scss">
.wv-main {
  margin-top: 10px;
}
</style>