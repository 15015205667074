<template>
  <div>
    <div class="card p-4 mt-4">
      <div class="fs24">
        {{ $t("TonKeeper") }}
        <div>
          <hr>
          <p>Install TK wallet: {{ installTK }}</p>
          <p>Connection wallet TK: {{ connectedWalletTK ? "true" : "false" }}</p>
          <p>My Address in TK: {{ userFriendlyAddressTK }}</p>
          <p>Raw Address in TK: {{ rawAddressTK }}</p>
          <p>Chain TK: {{ connectedWalletTK?.account?.chain }}</p>
          <p>Balance in TK: {{ balanceWallet }}</p>

          <hr>
          <div>
            <div class="mb-2">
              <AuButton
                bordered
                center
                width="auto"
                @click="installTon"
              >
                Install TK
              </AuButton>
            </div>

            <div class="mb-2">
              <AuButton
                bordered
                center
                width="auto"
                @click="connectTon"
              >
                Connect TK
              </AuButton>
            </div>

            <div class="mb-2">
              <AuButton
                bordered
                center
                width="auto"
                @click="getBalanceTon(rawAddressTK)"
              >
                Get balance TK
              </AuButton>
            </div>

            <div class="mb-2">
              <AuButton
                bordered
                center
                width="auto"
                @click="disconnectTon"
              >
                Disconnect TK
              </AuButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TonKeeper from "@/mixins/TonKeeper";
import { useTonAddress } from "@townsquarelabs/ui-vue";

export default {
  name: "TonKeeper",
  mixins: [TonKeeper],
  setup() {
    const userFriendlyAddressTK = useTonAddress();
    const rawAddressTK = useTonAddress(false);

    return {
      userFriendlyAddressTK,
      rawAddressTK
    };
  },
  data() {
    return {
      loading: false
    };
  },

  computed: {
    isLightTheme() {
      return this.$store.getters.isLightTheme;
    }
  },
  methods: {
    installTon() {
      window.open("https://tonkeeper.com/", "_blank");
    }
  }
};
</script>

<style scoped lang="scss"></style>
